<template>
  <div :id="`global-checkbox-${id}`" class="d-inline-block" :class="classes">
    <input
      type="checkbox"
      :name="name"
      :id="id"
      :checked="checked"
      class="form-check-input"
      :class="classes"
      v-model="model"
      :value="value"
      @input="inputChanged"
      :disabled="disabled"
      :data-cy="`checkbox-${id}`"
    />
    <label
      @click="disabled ? '' : labelClickEvent"
      class="form-check-label"
      :class="classes"
      :for="id"
      :data-cy="`checkbox-label-${id}`"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "GlobalCheckbox",
  props: {
    modelValue: {
      type: [String, Array, Boolean],
    },
    value: {
      type: [String, Boolean, Object],
    },
    name: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    classes: {
      type: [Object, String],
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
    setValue: {
      type: [Boolean, Number],
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:checked"],
  components: {},
  data() {
    return {
      options: [],
      show: this.checked,
      defaultValue: this.setValue,
    };
  },
  created() {},
  mounted() {},
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    inputChanged(event) {
      this.$emit("inputChanged", event.target.checked);
    },
    labelClickEvent() {
      if (
        this.name == "billing_information_differs" ||
        this.name == "car_service"
      ) {
        this.show = !this.show;
        this.emitter.emit("show-collapsed-information", this.show);
      }

      if (this.name == "car_value") {
        this.defaultValue = !this.defaultValue;
        this.emitter.emit("change-car-value-value", this.defaultValue);
      }

      if (this.name == "car_service") {
        this.defaultValue = !this.defaultValue;
        this.emitter.emit("change-car-service-value", this.defaultValue);
      }

      if (this.name == "used_cars") {
        this.defaultValue = !this.defaultValue;
        this.emitter.emit("change-used-cars-value", this.defaultValue);
      }

      if (this.name == "damaged_cars") {
        this.defaultValue = !this.defaultValue;
        this.emitter.emit("change-damaged-cars-value", this.defaultValue);
      }
    },
  },
};
</script>

<style lang="scss">
.is-invalid .form-check-input {
  border: 1px solid #f06548;
}
.form-check-input {
  cursor: pointer;
}
[active-exchange="carservice"] {
  .form-check-input:checked {
    background-color: var(--vz-carservice);
    border-color: var(--vz-carservice);
  }
}
</style>
