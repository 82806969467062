import NotificationService from "@/api/notifications";

const getPopupNotifications = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.getPopupNotifications(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createPopupNotification = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.createPopupNotification(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deletePopupNotification = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.deletePopupNotification(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnPopupNotification = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.getOwnPopupNotification(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const markPopupAsRead = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.markPopupAsRead(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getInternalNotifications = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.getInternalNotifications(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const markInternalNotificationsAsRead = (context, payload) => {
  return new Promise((resolve, reject) => {
    NotificationService.markInternalNotificationsAsRead(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getPopupNotifications,
  createPopupNotification,
  deletePopupNotification,
  getOwnPopupNotification,
  markPopupAsRead,
  getInternalNotifications,
  markInternalNotificationsAsRead,
};
