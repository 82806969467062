const state = {
  paginationSettings: {},
  lastVehicleId: null,
  lastUrl: null,
  backUrl: null,
  activeExchange: "carvalue",
  repairRedirectUrl: null,
};

export default state;
