import { mapActions } from "vuex";
import router from "../router";
import store from "@/store";
import i18n from "../i18n";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  methods: {
    ...mapActions({
      setRememberMe: "users/setRememberMe",
    }),
    getRememberTokenFromCookie() {
      let rememberToken = null;
      const cookies = document.cookie.split(";");
      cookies.forEach((item) => {
        const tokenArray = item.split("=");
        if (tokenArray[0].trim() === "remember_token") {
          rememberToken = tokenArray[1];
        }
      });
      return rememberToken;
    },
    setRememberTokenToCookie(token, rememberMeFromUrl) {
      const rememberToken = token.trim();
      let expireDate = null;
      const rememberMe = store.getters["users/getRememberMe"];
      if (
        rememberMe ||
        (rememberMeFromUrl && router.currentRoute.value.query.rememberMe)
      ) {
        expireDate = new Date(
          Date.now() + 400 * 24 * (60 * 60 * 1000)
        ).toUTCString();
        if (rememberMeFromUrl) {
          this.setRememberMe(true);
        }
      } else {
        expireDate = new Date(Date.now() + 24 * (10 * 60 * 1000)).toUTCString();
      }
      // document.cookie = `remember_token=${rememberToken}; Expires=${expireDate}; path=/; secure; HostOnly;`;
      // Safari nem engedi a secure flaget

      const host = location.host;
      const isRepairLocalHost = location.hostname == "repair.localhost";
      if (host.split(".").length === 1) {
        document.cookie = `remember_token=${rememberToken}; Expires=${expireDate}; path=/; domain=${".carvalue.eu"}`;
      } else if (!isRepairLocalHost && host.split(".").length <= 3) {
        let domainParts = host.split(".");
        domainParts.shift();
        let domain = domainParts.join(".");
        domain = "." + domainParts.join(".");
        document.cookie = `remember_token=${rememberToken}; Expires=${expireDate}; path=/; domain=${".carvalue.eu"}`;
      } else {
        document.cookie = `remember_token=${rememberToken}; Expires=${expireDate}; path=/; domain=${".carvalue.eu"}`;
      }

      // document.cookie = `remember_token=${rememberToken}; Expires=${expireDate}; path=/; HostOnly;`;
      document.cookie = `remember_token=${rememberToken}; Expires=${expireDate}; path=/; domain=${".carvalue.eu"}`;
    },
    handleSessionExpired() {
      toast.error(i18n.global.t("app.notifications.sessionExpired"));
      store.dispatch("users/logOut");
      router.push("/login");
    },
  },
};
