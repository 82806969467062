import UserService from "@/api/users";
import i18n from "@/i18n";
import deleteAllCookiesFactory from "delete-all-cookies";
const clearCookies = deleteAllCookiesFactory(window);

const loginUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.loginUser(payload)
      .then((res) => {
        context.commit("SET_LOGIN_USER", [res.user]);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const validateToken = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.validateToken(payload)
      .then((res) => {
        context.commit("SET_USER_PROFILE", [res.user, res.company]);
        context.commit("SET_AUTH_TOKEN", res.token);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const refreshUserData = (context, payload) => {
  if (payload) {
    // context.commit("SET_USER_PROFILE", [payload.user, payload.company]);
    context.commit("SET_AUTH_TOKEN", payload.token);
    i18n.global.locale = payload.lang;
    // context.commit("SET_LANGUAGE", payload.lang);
  }
};

const setIsLoadingGetUser = (context, payload) => {
  context.commit("SET_IS_LOADING_GET_USER", payload);
};

const checkUserByEmail = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.checkUserByEmail(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const registerUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.registerUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const requestPasswordReset = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.requestPasswordReset(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const resetPassword = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.resetPassword(payload)
      .then((res) => {
        context.commit("SET_USER_PROFILE", [res.user, res.company]);
        context.commit("SET_AUTH_TOKEN", res.token);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const logOut = (context) => {
  context.commit("RESET_USER_STATE");
  context.commit("SET_REMEMBER_ME", false);
  // document.cookie =
  //   "remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure; HostOnly;";
  // Safari nem engedi a secure flaget

  // const cookies = document.cookie.split(";");

  // Loop through all cookies
  // for (let i = 0; i < cookies.length; i++) {
  //   // Get the cookie name
  //   const cookie = cookies[i];
  //   const eqPos = cookie.indexOf("=");
  //   const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //   console.log(name);
  //   // Delete the cookie by setting its expiration date to a past date
  //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  // }

  // const host = location.host;
  // const isRepairLocalHost = location.hostname == "repair.localhost";

  clearCookies();
  // EZ ITT KI LETT SZEDVE MERT AMIKOR ÁTÁLLTUNK ÉLESRE NEM TÖRÖLTE A HOSTONLY:FALSE cookiet
  // ÉS NEM MŰKÖDÖTT A KIJELENTKEZÉS, EZÉRT VAN clearCookies() HELYETTE

  // if (host.split(".").length === 1) {
  //   document.cookie = `remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${".carvalue.eu"}`;
  // } else if (!isRepairLocalHost && host.split(".").length <= 3) {
  //   const domainParts = host.split(".");
  //   domainParts.shift();
  //   const domain = "." + domainParts.join(".");

  //   document.cookie = `remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=".carvalue.eu"}`;
  //   // document.cookie = `remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain="carvalue.eu"}`;
  //   // document.cookie = `remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain="repair.carvalue.eu"}`;
  // } else {
  //   document.cookie = `remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${".carvalue.eu"}`;
  // }

  // document.cookie =
  //   "remember_token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; HostOnly";
};

const getUserList = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getUserList(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const checkForgotKey = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.checkForgotKey(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getFiltersBySearchKey = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getFiltersBySearchKey(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserListForExcel = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getUserListForExcel(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLoginHistory = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getLoginHistory(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const activateUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.activateUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.createUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.deleteUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserById = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getUserById(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.updateUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const setRememberMe = (context, payload) => {
  context.commit("SET_REMEMBER_ME", payload);
};

const blockUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.blockUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const inactivateUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.inactivateUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const reactivateUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.reactivateUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addBuyerActivityId = (context, payload) => {
  context.commit("SET_BUYER_ACTIVITY_ID", payload);
};

// Get User Activity

const getUserActivity = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getUserActivity(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const clearLoginUser = (context) => {
  context.commit("SET_LOGIN_USER", [null]);
};

export default {
  loginUser,
  validateToken,
  refreshUserData,
  setIsLoadingGetUser,
  checkUserByEmail,
  registerUser,
  requestPasswordReset,
  resetPassword,
  logOut,
  getUserList,
  checkForgotKey,
  getFiltersBySearchKey,
  getUserListForExcel,
  getLoginHistory,
  activateUser,
  createUser,
  deleteUser,
  getUserById,
  updateUser,
  setRememberMe,
  blockUser,
  inactivateUser,
  reactivateUser,
  addBuyerActivityId,
  getUserActivity,
  clearLoginUser,
};
