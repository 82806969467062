import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import i18n from "../i18n";
import VARIABLES from "@/variables";
import cookieHandler from "@/mixins/cookieHandler";
import { useToast } from "vue-toastification";
const toast = useToast();

const AuthenticatedLayout = () => import("@/layouts/AuthenticatedLayout");
const UnAuthenticatedLayout = () => import("@/layouts/UnAuthenticatedLayout");

const routes = [
  {
    path: "/",
    component: AuthenticatedLayout,
    authRequired: true,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          title: "app.meta.title.dashboard",
          metaTags: {
            title: "app.meta.title.dashboard",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1, 2],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/Home"),
      },
      {
        path: "/users",
        name: "Users",
        meta: {
          title: "app.meta.title.users",
          metaTags: {
            title: "app.meta.title.users",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1, 2],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/Users"),
      },
      {
        path: "/notifications",
        name: "PopupNotificationList",
        meta: {
          title: "app.meta.title.popupNotificationList",
          metaTags: {
            title: "app.meta.title.popupNotificationList",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2],
          authRequired: true,
          disabledInCarservice: true,
        },
        component: () => import("@/views/dashboard/PopupNotificationList"),
      },
      {
        path: "/users/create/:role",
        name: "UsersCreate",
        meta: {
          title: "app.meta.title.usersCreate",
          metaTags: {
            title: "app.meta.title.usersCreate",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1, 2],
          authRequired: true,
        },
        beforeEnter: (to, from, next) => {
          const user = store.getters["users/getLoggedInUserProfile"]
            ? store.getters["users/getLoggedInUserProfile"]
            : "";

          if (
            to.params.role === "seller" ||
            to.params.role === "buyer" ||
            to.params.role === "manager" ||
            to.params.role === "country_admin"
          ) {
            if (
              (to.params.role === "manager" &&
                user.role_id !== VARIABLES.ROLES.TYPE.country_admin) ||
              (to.params.role === "country_admin" &&
                user.role_id !== VARIABLES.ROLES.TYPE.super_admin)
            ) {
              toast.error(i18n.global.t("app.notifications.pageNotAllowed"));
              next("/403");
            }
            next();
          }
          // toast.error(i18n.global.t("app.notifications.pageNotAllowed"));
          next("/404");
        },
        props: true,
        component: () => import("@/views/dashboard/users/Create"),
      },
      {
        path: "/users/:userId/edit",
        name: "EditUser",
        meta: {
          title: "app.meta.title.editUser",
          metaTags: {
            title: "app.meta.title.editUser",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1, 2],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/users/EditUser"),
      },
      {
        path: "/profile",
        name: "Profile",
        meta: {
          title: "app.meta.title.profile",
          metaTags: {
            title: "app.meta.title.profile",
            description: "",
            ogDescription: "",
          },
          authRequired: true,
        },
        component: () => import("@/views/dashboard/profile/Index"),
      },
      {
        path: "/profile/edit",
        name: "ProfileEdit",
        meta: {
          title: "app.meta.title.profileEdit",
          metaTags: {
            title: "app.meta.title.profileEdit",
            description: "",
            ogDescription: "",
          },
          authRequired: true,
        },
        component: () => import("@/views/dashboard/profile/Edit"),
      },
      {
        path: "/allVehicles",
        name: "VehicleList",
        meta: {
          title: "app.meta.title.allVehicles",
          breadcrumb: "allVehicles",
          metaTags: {
            title: "app.meta.title.allVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/onlineVehicles",
        name: "OnlineVehicleList",
        meta: {
          title: "app.meta.title.onlineVehicles",
          breadcrumb: "onlineVehicles",
          metaTags: {
            title: "app.meta.title.onlineVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/delayed",
        name: "DelayedVehicleList",
        meta: {
          title: "app.meta.title.delayedVehicles",
          breadcrumb: "delayedVehicles",
          metaTags: {
            title: "app.meta.title.delayedVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/draft",
        name: "DraftVehicleList",
        meta: {
          title: "app.meta.title.draftVehicles",
          breadcrumb: "draftVehicles",
          metaTags: {
            title: "app.meta.title.draftVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/blocked",
        name: "BlockedVehicleList",
        meta: {
          title: "app.meta.title.blockedVehicles",
          breadcrumb: "blockedVehicles",
          metaTags: {
            title: "app.meta.title.blockedVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/expired",
        name: "ExpiredVehicleList",
        meta: {
          title: "app.meta.title.expiredVehicles",
          breadcrumb: "expiredVehicles",
          metaTags: {
            title: "app.meta.title.expiredVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/archived",
        name: "ArchivedVehicleList",
        meta: {
          title: "app.meta.title.archivedVehicles",
          breadcrumb: "archivedVehicles",
          metaTags: {
            title: "app.meta.title.archivedVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/all",
        name: "AllOfferRequests",
        meta: {
          title: "app.meta.title.allVehicles",
          breadcrumb: "allVehicles",
          metaTags: {
            title: "app.meta.title.allVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: null,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/online",
        name: "OnlineOfferRequests",
        meta: {
          title: "app.meta.title.onlineVehicles",
          breadcrumb: "onlineVehicles",
          metaTags: {
            title: "app.meta.title.onlineVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: "online",
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/delayed",
        name: "DelayedOfferRequests",
        meta: {
          title: "app.meta.title.delayedVehicles",
          breadcrumb: "delayedVehicles",
          metaTags: {
            title: "app.meta.title.delayedVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: "delayed_upload",
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/draft",
        name: "DraftOfferRequests",
        meta: {
          title: "app.meta.title.draftVehicles",
          breadcrumb: "draftVehicles",
          metaTags: {
            title: "app.meta.title.draftVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: "draft",
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/blocked",
        name: "BlockedOfferRequests",
        meta: {
          title: "app.meta.title.blockedVehicles",
          breadcrumb: "blockedVehicles",
          metaTags: {
            title: "app.meta.title.blockedVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: "blocked",
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/expired",
        name: "ExpiredOfferRequests",
        meta: {
          title: "app.meta.title.expiredVehicles",
          breadcrumb: "expiredVehicles",
          metaTags: {
            title: "app.meta.title.expiredVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: "outdated",
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/archived",
        name: "ArchivedOfferRequests",
        meta: {
          title: "app.meta.title.archivedVehicles",
          breadcrumb: "archivedVehicles",
          metaTags: {
            title: "app.meta.title.archivedVehicles",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
          isVehicleList: true,
          isOfferRequestsByStatus: true,
          status: "archived",
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/:countryCode",
        name: "OfferRequests",
        meta: {
          title: "app.meta.title.offerRequests",
          breadcrumb: "offerRequests",
          metaTags: {
            title: "app.meta.title.offerRequests",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1, 2, 3],
          enabledToPermission: ["canBuy"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_requests/filter/:filterId",
        name: "OfferRequestsWithFilter",
        meta: {
          title: "app.meta.title.offerRequests",
          breadcrumb: "offerRequests",
          metaTags: {
            title: "app.meta.title.offerRequests",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [3],
          enabledToPermission: ["canBuy"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/vehicles/VehicleList"),
      },
      {
        path: "/offer_request/:id",
        name: "VehicleDetails",
        meta: {
          title: "app.meta.title.vehicleDetails",
          breadcrumb: "vehicleDetails",
          metaTags: {
            title: "app.meta.title.vehicleDetails",
            description: "",
            ogDescription: "",
          },
          authRequired: true,
          enabledToRole: [1, 2, 3, 4],
        },
        component: () => import("@/views/dashboard/vehicles/VehicleDetails"),
      },
      {
        path: "/currentOffers",
        name: "CurrentOffers",
        meta: {
          title: "app.meta.title.currentOffers",
          breadcrumb: "currentOffers",
          metaTags: {
            title: "app.meta.title.currentOffers",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [3],
          enabledToPermission: ["canBuy"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/ownOffers/OwnOfferList"),
      },
      {
        path: "/expiredOffers",
        name: "ExpiredOffers",
        meta: {
          title: "app.meta.title.expiredOffers",
          breadcrumb: "expiredOffers",
          metaTags: {
            title: "app.meta.title.expiredOffers",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [3],
          enabledToPermission: ["canBuy"],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/ownOffers/OwnOfferList"),
      },
      {
        path: "/sales/without-sale",
        name: "WithoutSalesScreen",
        meta: {
          title: "app.meta.title.withoutSale",
          breadcrumb: "withoutSale",
          metaTags: {
            title: "app.meta.title.withoutSale",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/data-filled",
        name: "DataFilledSalesScreen",
        meta: {
          title: "app.meta.title.dataFilled",
          breadcrumb: "dataFilled",
          metaTags: {
            title: "app.meta.title.dataFilled",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/data-sent",
        name: "DataSentSalesScreen",
        meta: {
          title: "app.meta.title.dataSent",
          breadcrumb: "dataSent",
          metaTags: {
            title: "app.meta.title.dataSent",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/vehicle-taken",
        name: "VehicleTakenSalesScreen",
        meta: {
          title: "app.meta.title.vehicleTaken",
          breadcrumb: "vehicleTaken",
          metaTags: {
            title: "app.meta.title.vehicleTaken",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/invoice",
        name: "InvoiceSalesScreen",
        meta: {
          title: "app.meta.title.invoice",
          breadcrumb: "invoice",
          metaTags: {
            title: "app.meta.title.invoice",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/closed",
        name: "ClosedSalesScreen",
        meta: {
          title: "app.meta.title.closed",
          breadcrumb: "closed",
          metaTags: {
            title: "app.meta.title.closed",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/failed",
        name: "FailedSalesScreen",
        meta: {
          title: "app.meta.title.failed",
          breadcrumb: "failed",
          metaTags: {
            title: "app.meta.title.failed",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/all",
        name: "AllSalesScreen",
        meta: {
          title: "app.meta.title.all",
          breadcrumb: "all",
          metaTags: {
            title: "app.meta.title.all",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sales/archived",
        name: "ArchivedSalesScreen",
        meta: {
          title: "app.meta.title.archived",
          breadcrumb: "archived",
          metaTags: {
            title: "app.meta.title.archived",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
          isSales: true,
        },
        component: () => import("@/views/dashboard/sales/SalesScreen"),
      },
      {
        path: "/sale/:id",
        name: "SalesDetails",
        meta: {
          title: "app.meta.title.salesDetails",
          breadcrumb: "salesDetails",
          metaTags: {
            title: "app.meta.title.salesDetails",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 4],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/sales/SalesDetails"),
      },
      {
        path: "/vehicles/create",
        name: "New Vehicle",
        meta: {
          title: "app.meta.title.vehiclesUpload",
          metaTags: {
            title: "app.meta.title.vehiclesUpload",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/vehicles/Create"),
      },
      {
        path: "/vehicles/repair/create",
        name: "New Repair Vehicle",
        meta: {
          title: "app.meta.title.vehiclesUpload",
          metaTags: {
            title: "app.meta.title.vehiclesUpload",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/vehicles/repair/Create"),
      },
      {
        path: "/vehicles/edit/:offerId",
        name: "EditVehicle",
        meta: {
          title: "app.meta.title.editVehicle",
          metaTags: {
            title: "app.meta.title.editVehicle",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/vehicles/Edit"),
      },
      {
        path: "/vehicles/repair/edit/:offerId",
        name: "EditRepairVehicle",
        meta: {
          title: "app.meta.title.editVehicle",
          metaTags: {
            title: "app.meta.title.editVehicle",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [2, 3],
          enabledToPermission: ["canSell"],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/vehicles/repair/Edit"),
      },
      {
        path: "/countries",
        name: "Countries",
        meta: {
          title: "app.meta.title.countries",
          metaTags: {
            title: "app.meta.title.countries",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1],
          authRequired: true,
        },
        component: () => import("@/views/dashboard/Countries"),
      },
      {
        path: "/report",
        name: "ReportModule",
        meta: {
          title: "app.meta.title.report",
          breadcrumb: "reportModule",
          metaTags: {
            title: "app.meta.title.report",
            description: "",
            ogDescription: "",
          },
          enabledToRole: [1, 2, 4],
          authRequired: true,
          isVehicleList: true,
        },
        component: () => import("@/views/dashboard/report/Report"),
      },
    ],
  },
  {
    path: "",
    component: UnAuthenticatedLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          title: "app.meta.title.login",
          metaTags: {
            title: "app.meta.title.login",
            description: "",
            ogDescription: "",
          },
          authRequired: false,
          isRedirectToLogin: true,
          isUnAuthenticatedScreen: true,
        },
        component: () => import("@/views/account/Login"),
      },
      {
        path: "/register",
        name: "Register",
        meta: {
          title: "app.meta.title.register",
          metaTags: {
            title: "app.meta.title.register",
            description: "",
            ogDescription: "",
          },
          authRequired: false,
          isRedirectToLogin: true,
          isUnAuthenticatedScreen: true,
        },
        component: () => import("@/views/account/Register"),
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        meta: {
          title: "app.meta.title.forgotPassword",
          metaTags: {
            title: "app.meta.title.forgotPassword",
            description: "",
            ogDescription: "",
          },
          authRequired: false,
          isRedirectToLogin: true,
          isUnAuthenticatedScreen: true,
        },
        component: () => import("@/views/account/ForgotPassword"),
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        meta: {
          title: "app.meta.title.resetPassword",
          metaTags: {
            title: "app.meta.title.resetPassword",
            description: "",
            ogDescription: "",
          },
          authRequired: false,
          isRedirectToLogin: true,
          isUnAuthenticatedScreen: true,
        },
        component: () => import("@/views/account/ResetPassword"),
      },
      {
        path: "/two-steps",
        name: "TwoSteps",
        meta: {
          title: "app.meta.title.twoStepsAuthentication",
          metaTags: {
            title: "app.meta.title.twoStepsAuthentication",
            description: "",
            ogDescription: "",
          },
          authRequired: false,
          isRedirectToLogin: true,
          isUnAuthenticatedScreen: true,
        },
        component: () => import("@/views/account/TwoStepsAuthentication"),
      },
      {
        path: "/terms-of-use",
        name: "TermsOfUse",
        meta: {
          title: "app.meta.title.termsOfUse",
          metaTags: {
            title: "app.meta.title.termsOfUse",
            description: "",
            ogDescription: "",
          },
          authRequired: false,
        },
        component: () => import("@/views/pages/TermsOfUse"),
      },
      {
        path: "/outside-login",
        name: "OutsideLogin",
        component: () => import("@/views/outside/OutsideLogin"),
        meta: {
          title: "app.meta.title.OutsideLogin",
          metaTags: {
            title: "app.meta.title.OutsideLogin",
            description: "",
            ogDescription: "",
          },
          needsAuth: false,
        },
      },
      {
        path: "/outside-vehicle",
        name: "OutsideVehicle",
        component: () => import("@/views/outside/OutsideVehicle"),
        meta: {
          title: "app.meta.title.OutsideVehicle",
          metaTags: {
            title: "app.meta.title.OutsideVehicle",
            description: "",
            ogDescription: "",
          },
          needsAuth: true,
        },
      },
      {
        path: "/outside-vehicle/done",
        name: "OutsideDoneVehicle",
        component: () => import("@/views/outside/OutsideDoneVehicle"),
        meta: {
          title: "app.meta.title.OutsideVehicle",
          metaTags: {
            title: "app.meta.title.OutsideVehicle",
            description: "",
            ogDescription: "",
          },
          needsAuth: true,
        },
      },
    ],
  },
  {
    path: "/redirect",
    name: "RedirectScreen",
    meta: {
      title: "app.meta.title.redirect",
      breadcrumb: "redirect",
      metaTags: {
        title: "app.meta.title.redirect",
        description: "",
        ogDescription: "",
      },
    },
    component: () => import("@/views/Redirect"),
  },
  {
    path: "/landing",
    name: "LandingPage",
    component: () => import("@/views/landing/index.vue"),
    meta: {
      title: "app.meta.title.landing",
      metaTags: {
        title: "app.meta.title.landing",
        description: "",
        ogDescription: "",
      },
      needsAuth: false,
      authRequired: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/landing/index.vue"),
    meta: {
      title: "app.meta.title.privacyPolicy",
      metaTags: {
        title: "app.meta.title.privacyPolicy",
        description: "",
        ogDescription: "",
      },
      withoutRedirectDashboard: true,
    },
  },
  {
    path: "/carvalue-terms-and-conditions",
    name: "CarvalueTermsAndConditions",
    component: () => import("@/views/landing/index.vue"),
    meta: {
      title: "app.meta.title.termsOfUse",
      metaTags: {
        title: "app.meta.title.termsOfUse",
        description: "",
        ogDescription: "",
      },
      withoutRedirectDashboard: true,
    },
  },
  {
    path: "/repair-terms-and-conditions",
    name: "RepairTermsAndConditions",
    component: () => import("@/views/landing/index.vue"),
    meta: {
      title: "app.meta.title.termsOfUse",
      metaTags: {
        title: "app.meta.title.termsOfUse",
        description: "",
        ogDescription: "",
      },
      withoutRedirectDashboard: true,
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/views/pages/Maintenance"),
    meta: {
      title: "app.meta.title.maintenance",
      metaTags: {
        title: "app.meta.title.maintenance",
        description: "",
        ogDescription: "",
      },
    },
  },
  {
    path: "/403",
    name: "Error403",
    component: () => import("@/views/errors/403"),
    meta: {
      title: "app.meta.title.error403",
      metaTags: {
        title: "app.meta.title.error403",
        description: "",
        ogDescription: "",
      },
    },
  },
  {
    path: "/404",
    name: "Error404",
    component: () => import("@/views/errors/404"),
    meta: {
      title: "app.meta.title.error404",
      metaTags: {
        title: "app.meta.title.error404",
        description: "",
        ogDescription: "",
      },
    },
  },
  {
    path: "/500",
    name: "Error500",
    component: () => import("@/views/errors/500"),
    meta: {
      title: "app.meta.title.error500",
      metaTags: {
        title: "app.meta.title.error500",
        description: "",
        ogDescription: "",
      },
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    beforeEnter(to, from, next) {
      next("/");
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

const guard = (to, from, next) => {
  const authRequired = to.meta.authRequired;
  const needsAuth = to.meta.needsAuth;
  const allCountriesByCode = store.getters["countries/allCountriesByCode"];
  const hasAuthToken = store.getters["users/hasAuthToken"];
  const authToken = store.getters["users/getAuthToken"];
  const rememberToken = cookieHandler.methods.getRememberTokenFromCookie();
  const loggedInUser = store.getters["users/getLoggedInUserProfile"];
  const role_id = store.getters["users/getLoggedInUserProfile"]
    ? store.getters["users/getLoggedInUserProfile"].role_id
    : "";
  const user_permissions = store.getters["users/getLoggedInUserProfile"]
    ? store.getters["users/getLoggedInUserProfile"].user_permissions
    : [];

  const lastUrl = store.getters["route/lastUrl"]
    ? store.getters["route/lastUrl"]
    : "";
  const user = store.getters["users/getLoggedInUserProfile"];
  const activeExchange = store.getters["route/activeExchange"];
  const token = store.getters["outside/getValidGuest"];
  const hasDatas = store.getters["outside/getHasDatas"];
  const firstLoginToOutsideForm = store.getters["outside/getFirstLogin"];
  const isLoadingGetUser = store.getters["users/isLoadingGetUser"];
  const carValueExchangeURL = process.env.VUE_APP_BASE_URL;
  const carServiceExchangeURL = process.env.VUE_APP_REPAIR_URL;
  const salesURL = process.env.VUE_APP_SALES_URL;
  const isCarValueExchangeURL = location.origin == carValueExchangeURL;
  const isCarServiceExchangeURL = location.origin == carServiceExchangeURL;
  const isSalesURL = location.origin == salesURL;
  const isRepairLocalHost = location.hostname == "repair.localhost";
  const isSalesLocalHost = location.hostname == "sales.localhost";
  const isMaintenance = process.env.VUE_APP_MAINTENANCE;
  const loginUser = store.getters["users/loginUser"];

  function isSeller() {
    return (
      user &&
      user.role_id === VARIABLES.ROLES.TYPE.trader &&
      user.user_permissions.includes(VARIABLES.PERMISSIONS.TYPE.canSell)
    );
  }

  function isBuyer() {
    return (
      user &&
      user.role_id === VARIABLES.ROLES.TYPE.trader &&
      user.user_permissions.includes(VARIABLES.PERMISSIONS.TYPE.canBuy)
    );
  }

  function isCountryAdmin() {
    return user && user.role_id === VARIABLES.ROLES.TYPE.country_admin;
  }

  function isSuperAdmin() {
    return user && user.role_id === VARIABLES.ROLES.TYPE.super_admin;
  }

  function isManager() {
    return user && user.role_id === VARIABLES.ROLES.TYPE.manager;
  }

  if (isMaintenance === "enabled" && to.path !== "/maintenance") {
    location.href = "/maintenance";
  }

  if (to.name !== "TwoSteps" && loginUser) {
    store.dispatch("users/clearLoginUser");
  }

  if (
    (hasAuthToken &&
      !loggedInUser &&
      isCarServiceExchangeURL &&
      to.name != "RedirectScreen") ||
    (hasAuthToken &&
      loggedInUser &&
      isCarServiceExchangeURL &&
      to.name != "RedirectScreen" &&
      rememberToken !== authToken)
  ) {
    store.commit("route/SET_REPAIR_REDIRECT_URL", location.pathname);
    next("/redirect");
  }
  if (needsAuth) {
    if (token) {
      if (firstLoginToOutsideForm) {
        i18n.global.locale = store.getters["lang/activeLanguage"];
        toast.success(i18n.global.t("app.notifications.logged_in"));
        return next();
      }
      return next();
    } else {
      i18n.global.locale = store.getters["lang/activeLanguage"];
      toast.error(i18n.global.t("app.notifications.pageNotAllowed"));
      location.href = salesURL + "/outside-login";
    }
  }

  if (from.name !== "OutsideVehicle" && token && !hasDatas) {
    return next("/outside-vehicle");
  } else if (token && !hasDatas) {
    location.href = salesURL + "/outside-vehicle";
  }

  if (from.name !== "OutsideDoneVehicle" && token && hasDatas) {
    return next("/outside-vehicle/done");
  } else if (token && hasDatas) {
    location.href = salesURL + "/outside-vehicle/done";
  }

  if (to.name !== "VehicleDetails" && to.path !== lastUrl) {
    store.dispatch("route/setLastUrl", null);
    store.dispatch("route/setPaginationSettings", {});
    store.dispatch("route/setLastVehicleId", null);
  }

  if (to.name === "ResetPassword" && to.query.forgottenPasswordKey == null) {
    next("/login");
  }
  if (
    to.path === "/" &&
    rememberToken != null &&
    (isSuperAdmin() || isCountryAdmin())
  ) {
    next("/dashboard");
  } else if (to.path === "/" && rememberToken != null && isManager()) {
    next("/report");
  } else if (to.path === "/" && rememberToken != null && isSeller()) {
    next("/onlineVehicles");
  } else if (to.path === "/" && rememberToken != null && isBuyer()) {
    next(`/offer_requests/${VARIABLES.COUNTRY_VALUE_BY_ID[user.country_id]}`);
  } else if (to.path === "/" && (!rememberToken || !user)) {
    next("/landing");
  } else if (to.path === "/" && rememberToken != null && !hasAuthToken) {
    next("/profile/edit");
  }

  if (
    to.path === "/vehicles/create" &&
    store.getters["route/activeExchange"] !== "carvalue"
  ) {
    next("/vehicles/repair/create");
  }

  if (to.name === "OutsideLogin" && !isSalesURL && !isSalesLocalHost) {
    location.href =
      salesURL +
      "/outside-login" +
      `?lang=${store.getters["lang/activeLanguage"]}`;
  }

  if ((isSalesURL || isSalesLocalHost) && to.name !== "OutsideLogin") {
    location.href = carValueExchangeURL + "/landing";
  }

  if (
    to.meta.isRedirectToLogin &&
    isCarServiceExchangeURL &&
    !isRepairLocalHost &&
    rememberToken === null
  ) {
    location.href = carValueExchangeURL + "/login";
  }

  if (
    to.path === "/vehicles/repair/create" &&
    store.getters["route/activeExchange"] !== "carservice"
  ) {
    next("/vehicles/create");
  }

  let hasPermission = false;
  if (to.meta.enabledToPermission) {
    to.meta.enabledToPermission.forEach((permission) => {
      user_permissions.forEach((userPermission) => {
        if (userPermission === VARIABLES.PERMISSIONS.TYPE[permission]) {
          hasPermission = true;
        }
      });
    });
  }

  if (!isLoadingGetUser) {
    if (
      role_id === VARIABLES.ROLES.TYPE.trader &&
      to.name === "OfferRequests" &&
      !loggedInUser.selected_countries.includes(
        parseInt(
          allCountriesByCode
            ? allCountriesByCode[to.params.countryCode]
            : VARIABLES.COUNTRY_VALUE_BY_CODE[to.params.countryCode]
        )
      )
    ) {
      next("/currentOffers");
    }
    if (
      rememberToken != null &&
      ((to.meta.enabledToRole && !to.meta.enabledToRole.includes(role_id)) ||
        (to.meta.enabledToPermission &&
          !hasPermission &&
          role_id === VARIABLES.ROLES.TYPE.trader) ||
        (role_id === VARIABLES.ROLES.TYPE.country_admin &&
          to.name === "OfferRequests" &&
          loggedInUser.country_id !==
            parseInt(allCountriesByCode[to.params.countryCode])))
    ) {
      i18n.global.locale = store.getters["lang/activeLanguage"];
      toast.error(i18n.global.t("app.notifications.pageNotAllowed"));
      next("/403");
    } else if (activeExchange == "carservice" && to.meta.disabledInCarservice) {
      next("/");
    }
  }

  if (isSuperAdmin() && to.name === "OfferRequests") {
    next("/404");
  }

  if (
    hasAuthToken &&
    to.meta.isUnAuthenticatedScreen &&
    isCarValueExchangeURL
  ) {
    next("/");
  } else if (
    hasAuthToken &&
    to.meta.isUnAuthenticatedScreen &&
    isCarServiceExchangeURL
  ) {
    if (isSuperAdmin() || isCountryAdmin()) {
      next("/dashboard");
    } else if (isManager()) {
      next("/report");
    } else if (isSeller()) {
      next("/onlineVehicles");
    } else if (isBuyer()) {
      next(`/offer_requests/${VARIABLES.COUNTRY_VALUE_BY_ID[user.country_id]}`);
    }
  }

  if (authRequired) {
    if (rememberToken != null && hasAuthToken) {
      // Van cookie-ban rememberToken, így lehet frissíteni a lejárati dátumot
      cookieHandler.methods.setRememberTokenToCookie(rememberToken);
      return next();
    } else if (hasAuthToken) {
      // Lejárt session, mert cookie-ban már nincs rememberToken, de a store-ban még igen
      cookieHandler.methods.handleSessionExpired();
      return next("/login");
    } else {
      return next("/login");
    }
  } else {
    if (rememberToken != null && hasAuthToken) {
      // Van cookie-ban rememberToken, így lehet frissíteni a lejárati dátumot
      cookieHandler.methods.setRememberTokenToCookie(rememberToken);
      if (to.meta.withoutRedirectDashboard) {
        return next();
      }
    } else if (hasAuthToken) {
      // Lejárt session, mert cookie-ban már nincs rememberToken, de a store-ban még igen
      cookieHandler.methods.handleSessionExpired();
      return; // next("/login");
    }
    return next();
  }
};

router.beforeEach(guard);

export default router;
