<template>
  <div id="popup-notification-modal">
    <div class="message-wrapper">
      <div class="message" v-html="this.message" />
    </div>
    <div class="modal-footer">
      <div class="hstack buttons-wrapper">
        <global-checkbox
          id="used_vehicle"
          name="used_vehicle"
          v-model="markAsRead"
          :label="$t('app.views.popupNotificationModal.checkboxLabel')"
          :checked="markAsRead"
          :setValue="markAsRead"
          :disabled="isPreview"
        />
        <global-button
          :title="$t('app.views.popupNotificationModal.buttonText')"
          classes="btn-success"
          @buttonClicked="handleButtonClicked"
          id="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GlobalButton from "@/components/GlobalButton";
import GlobalCheckbox from "@/components/GlobalCheckbox";

export default {
  name: "PopupNotificationModal",
  props: {
    message: {
      type: String,
      required: true,
    },
    notificationId: {
      type: Number,
      required: true,
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    GlobalButton,
    GlobalCheckbox,
  },
  data() {
    return {
      loading: false,
      markAsRead: false,
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      markPopupAsRead: "notifications/markPopupAsRead",
    }),
    closeModal() {
      this.$emit("closeModal");
      this.emitter.emit("closeModal");
    },
    handleButtonClicked() {
      if (this.markAsRead) {
        this.markPopupAsRead({
          notificationId: this.notificationId,
        }).then(() => {
          this.$emit("markPopupAsRead");
          this.closeModal();
        });
      } else {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss">
#popup-notification-modal {
  padding: 4px;
  .buttons-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .modal-footer {
    padding: 0;
    margin-top: 16px;
    --vz-bg-opacity: 1;
    background-color: rgba(
      var(--vz-light-rgb),
      var(--vz-bg-opacity)
    ) !important;
  }
  .modal-footer {
    position: sticky;
    background: white;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: -20px;
    padding-right: 20px;
    margin-right: -20px;
    padding-left: 20px;
    margin-left: -20px;
    bottom: -16px;
    .form-check-input {
      margin-right: 0.5rem;
    }
    .form-check-label {
      font-size: 12px;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 77px;
      left: 0;
      height: 16px;
      width: 100%;
      --vz-bg-opacity: 1;
      --vz-light-rgb: 243, 246, 249;
      background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0.451));
    }

    .buttons-wrapper {
      margin: 0;
    }
  }
  .message-wrapper {
    min-height: 20vh;
    .message {
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      word-break: break-word;
      > * {
        margin-bottom: 0;
      }
    }
  }
}
</style>
