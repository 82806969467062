const setPaginationSettings = (context, paginationSettings) => {
  context.commit("SET_PAGINATION_SETTINGS", paginationSettings);
};

const setLastVehicleId = (context, lastVehicleId) => {
  context.commit("SET_LAST_VEHICLE_ID", lastVehicleId);
};

const setLastUrl = (context, lastUrl) => {
  context.commit("SET_LAST_URL", lastUrl);
};

const setBackUrl = (context, backUrl) => {
  context.commit("SET_BACK_URL", backUrl);
};

const setActiveExchange = (context, activeExchange) => {
  context.commit("SET_ACTIVE_EXCHANGE", activeExchange);
};

const setActiveExchangeAfterLogin = (context, activeExchange) => {
  context.commit("SET_ACTIVE_EXCHANGE", activeExchange);
};

const setRepairRedirectUrl = (context, url) => {
  context.commit("SET_REPAIR_REDIRECT_URL", url);
};

export default {
  setPaginationSettings,
  setLastVehicleId,
  setLastUrl,
  setBackUrl,
  setActiveExchange,
  setActiveExchangeAfterLogin,
  setRepairRedirectUrl,
};
