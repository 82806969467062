import { createApp } from "vue";
import App from "./views/App.vue";
import router from "./router";
import store from "./store";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
//import Maska from "maska";
import VueFeather from "vue-feather";
import Particles from "particles.vue3";
import "@/assets/scss/config/default/app.scss";
import "@vueform/slider/themes/default.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VOtpInput from "vue3-otp-input";
import mitt from "mitt";
import Popper from "vue3-popper";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const app = createApp(App);
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

const toastOptions = {
  // You can set your default options here
};

app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  //.use(Maska)
  .use(Particles)
  .use(i18n)
  .use(vClickOutside)
  .use(Toast, toastOptions)
  .component("v-otp-input", VOtpInput)
  .component("Popper", Popper)
  .mount("#app");
