const state = {
  // outside-login response
  loggedInGuestVehicle: {},

  // can see the form or not
  validGuest: false,

  // identification
  bidId: null,
  pin: null,

  // there are datas or not
  hasDatas: null,

  // first login watcher
  firstLogin: null,
};

export default state;
