import { createStore } from "vuex";
import form from "./modules/form";
import lang from "./modules/lang";
import users from "./modules/users";
import profile from "./modules/profile";
import countries from "./modules/countries";
import files from "./modules/files";
import notifications from "./modules/notifications";
import vehicles from "./modules/vehicles";
import route from "./modules/route";
import outside from "./modules/outside";
import customVehicleFilter from "./modules/custom-vehicle-filter";
import report from "./modules/report";
import sales from "./modules/sales";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    form,
    lang,
    users,
    profile,
    countries,
    files,
    notifications,
    vehicles,
    route,
    outside,
    customVehicleFilter,
    report,
    sales,
  },
  plugins: [
    createPersistedState({
      key: "store",
      paths: ["users", "lang", "vehicles", "route", "outside", "countries"],
    }),
  ],
});
