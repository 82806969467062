<template>
  <div
    id="modal"
    class="modal"
    :class="[
      modalActive ? 'show' : '',
      component.isGallery ? 'gallery-modal' : '',
    ]"
    @click="component.isClosable ? close : ''"
  >
    <transition name="modal-animation-inner">
      <div
        class="modal-inner"
        @click.stop
        :class="{
          'modal-large-inner': largeModal,
          'gallery-modal-inner': component.isGallery,
          'modal-medium-inner': component.isMediumSize,
        }"
      >
        <div class="modal-header bg-light p-3" v-if="component.titleText">
          <h5 class="modal-title">{{ component.titleText }}</h5>
          <button
            type="button"
            class="btn-close"
            id="close-modal"
            @click="close"
            :data-cy="`button-close`"
          ></button>
        </div>
        <div
          :class="[
            'modal-component',
            component.isGallery ? 'gallery-modal-component' : '',
          ]"
        >
          <component
            v-bind:is="component.bind.is"
            v-bind="component.bind"
            v-on="component.on"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    component: {
      type: Object,
      required: true,
    },
    modalActive: Boolean,
  },
  components: {},
  data() {
    return {
      visualState: false,
    };
  },
  created() {},
  mounted() {
    this.popupItem = this.$el;
  },
  computed: {
    model() {
      return this.component.model;
    },
    largeModal() {
      return this.component.largeModal;
    },
  },
  methods: {
    close() {
      this.emitter.emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.show {
  display: block;
}

.modal {
  z-index: 9999;
}

.modal-inner {
  transition: all 1s ease-in-out;
  z-index: 20;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  height: auto;
  max-height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  pointer-events: initial;
}

.modal-iner::before {
  content: "";
  position: absolute;
  top: 70px;
  left: 0;
  height: 16px;
  width: 100%;
  background-color: linear-gradient(to top, rgba(255, 255, 255, 0), white);
}

.modal-component {
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(80vh - 70px);
  background: white;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modal-large-inner {
  width: 100%;
  max-width: 700px;
  top: 25%;
  bottom: 50px;
  transform: translate(-50%, -15%);
}

.modal-medium-inner {
  width: 50vw;
  @media screen and (max-width: 992px) {
    width: 80vw;
  }
}

.modal-header {
  height: 70px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  padding: 1rem 1rem;
}

.modal-header h3 {
  color: #000;
  margin-bottom: 0px;
}

.modal-dialog-container {
  background-color: #fff;
  border-radius: 5px;
  padding: 32px;
}

.create {
  background-color: #33b5e5;
}

.edit {
  background-color: #0277bd;
}

.delete {
  background-color: #ff3f3f;
}

.gallery-modal {
  background: rgba(0, 0, 0, 0.8);
}

.gallery-modal-component {
  background: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.gallery-modal-inner {
  background: transparent;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 575px) {
  .modal-inner {
    width: 95vw;
    max-height: 80vh;
  }
}
</style>
