import VehicleService from "@/api/vehicles";

const createFavouriteSearchVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.createFavouriteSearchVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getFavouriteSearchVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getFavouriteSearchVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteFavouriteSearchVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.deleteFavouriteSearchVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  createFavouriteSearchVehicle,
  getFavouriteSearchVehicle,
  deleteFavouriteSearchVehicle,
};
