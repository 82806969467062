const SET_BIDDING_FORM_DATA = (state, data) => {
  state.getVehicleDraft.bidding = data;
};

const SET_VEHICLE_FORM_DATA = (state, data) => {
  state.getVehicleDraft.vehicle = data;
};

const SET_OFFER_DATA = (state, data) => {
  state.getOffer = data;
};

export default {
  SET_BIDDING_FORM_DATA,
  SET_VEHICLE_FORM_DATA,
  SET_OFFER_DATA,
};
