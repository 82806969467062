const SET_PAGINATION_SETTINGS = (state, paginationSettings) => {
  state.paginationSettings = paginationSettings;
};

const SET_LAST_VEHICLE_ID = (state, lastVehicleId) => {
  state.lastVehicleId = lastVehicleId;
};

const SET_LAST_URL = (state, lastUrl) => {
  state.lastUrl = lastUrl;
};

const SET_BACK_URL = (state, backUrl) => {
  state.backUrl = backUrl;
};

const SET_ACTIVE_EXCHANGE = (state, activeExchange) => {
  state.activeExchange = activeExchange;
};

const SET_REPAIR_REDIRECT_URL = (state, url) => {
  state.repairRedirectUrl = url;
};

export default {
  SET_PAGINATION_SETTINGS,
  SET_LAST_VEHICLE_ID,
  SET_LAST_URL,
  SET_BACK_URL,
  SET_ACTIVE_EXCHANGE,
  SET_REPAIR_REDIRECT_URL,
};
