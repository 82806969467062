const SET_COUNTRIES = (state, data) => {
  state.allCountries = data;
};

const SET_COUNTRIES_BY_ID = (state, data) => {
  state.allCountriesByID = data;
};

const SET_COUNTRIES_BY_CODE = (state, data) => {
  state.allCountriesByCode = data;
};

export default {
  SET_COUNTRIES,
  SET_COUNTRIES_BY_ID,
  SET_COUNTRIES_BY_CODE,
};
