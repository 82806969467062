const lastVehicleId = (state) => state.lastVehicleId;
const paginationSettings = (state) => state.paginationSettings;
const lastUrl = (state) => state.lastUrl;
const backUrl = (state) => state.backUrl;
const activeExchange = (state) => state.activeExchange;
const repairRedirectUrl = (state) => state.repairRedirectUrl;

export default {
  lastVehicleId,
  paginationSettings,
  lastUrl,
  backUrl,
  activeExchange,
  repairRedirectUrl,
};
