const state = {
  authToken: null,
  loggedInUserProfile: null,
  rememberMe: false,
  isLoadingGetUser: true,
  buyerActivityId: null,
  loginUser: null,
};

export default state;
