import { fetchData, apiURL } from "./api_helper";

// Users API services

// LOGIN

const loginUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/login";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// VALIDATE TOKEN

const validateToken = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/validate";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// REQUEST PASSWORD RESET

const requestPasswordReset = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/password/forgot";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// RESET PASSWORD

const resetPassword = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/password/reset";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// CHECK USER BY EMAIL

const checkUserByEmail = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/email/check";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// REGISTER USER

const registerUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/register";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserList = (
  requestBody,
  roles,
  permissions,
  searchText,
  page,
  limit,
  orders,
  skipSaveFilter
) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/list`;
    if (roles) {
      endpoint.concat(`?roles=${roles}`);
    }
    if (permissions) {
      if (endpoint.includes("?")) {
        endpoint.concat(`&permissions=${permissions}`);
      } else {
        endpoint.concat(`?permissions=${permissions}`);
      }
    }
    if (searchText) {
      if (endpoint.includes("?")) {
        endpoint.concat(`&searchText=${searchText}`);
      } else {
        endpoint.concat(`?searchText=${searchText}`);
      }
    }
    if (endpoint.includes("?")) {
      endpoint.concat(`&page=${page}&limit=${limit}`);
    } else {
      endpoint.concat(`?page=${page}&limit=${limit}`);
    }

    if (orders) {
      orders.forEach((order) => {
        if (endpoint.includes("?")) {
          endpoint.concat(`&orders[]=${order}`);
        } else {
          endpoint.concat(`?orders[]=${order}`);
        }
      });
    }

    if (endpoint.includes("?")) {
      endpoint.concat(`&skipSaveFilter=${skipSaveFilter}`);
    } else {
      endpoint.concat(`?skipSaveFilter=${skipSaveFilter}`);
    }

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const checkForgotKey = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/password/check";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getFiltersBySearchKey = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/me/filters`;

    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserListForExcel = (
  requestBody,
  roles,
  permissions,
  searchText,
  orders
) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/list/export`;
    if (roles) {
      endpoint.concat(`?roles=${roles}`);
    }
    if (permissions) {
      if (endpoint.includes("?")) {
        endpoint.concat(`&permissions=${permissions}`);
      } else {
        endpoint.concat(`?permissions=${permissions}`);
      }
    }
    if (searchText) {
      if (endpoint.includes("?")) {
        endpoint.concat(`&searchText=${searchText}`);
      } else {
        endpoint.concat(`?searchText=${searchText}`);
      }
    }
    if (orders) {
      orders.forEach((order) => {
        if (endpoint.includes("?")) {
          endpoint.concat(`&orders[]=${order}`);
        } else {
          endpoint.concat(`?orders[]=${order}`);
        }
      });
    }

    requestBody.generateExcel = true;
    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLoginHistory = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me/logins";

    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const activateUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}/activate`;
    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// CREATE USER

const createUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/create";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}`;
    fetchData(endpoint, "delete", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserById = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}`;
    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}`;

    fetchData(endpoint, "put", requestBody.formData, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const blockUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}/block`;
    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const inactivateUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}/inactivate`;
    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const reactivateUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/${requestBody.userId}/reactivate`;
    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get User Activity

const getUserActivity = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL +
      `/api/users/activity?${requestBody.interval}${requestBody.userId}`;
    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  loginUser,
  validateToken,
  requestPasswordReset,
  resetPassword,
  checkUserByEmail,
  registerUser,
  getUserList,
  checkForgotKey,
  getFiltersBySearchKey,
  getUserListForExcel,
  getLoginHistory,
  activateUser,
  createUser,
  deleteUser,
  getUserById,
  updateUser,
  blockUser,
  inactivateUser,
  reactivateUser,
  getUserActivity,
};
