<template>
  <div>
    <!-- Modal -->
    <transition name="zoom">
      <modal
        v-if="modalActive"
        :component="modalComponent"
        :key="modalKey"
        :modalActive="modalActive"
      ></modal>
    </transition>

    <div v-if="fsLoader" class="loading">Loading&#8230;</div>

    <div>
      <div id="backdrop"></div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Modal from "@/mixins/modal";
import cookieHandler from "@/mixins/cookieHandler";
import PopupNotificationModal from "@/layouts/modals/PopupNotificationModal.vue";
import VARIABLES from "@/variables";

export default {
  name: "App",
  props: {},
  mixins: [Modal],
  components: {},
  data() {
    return {
      popupNotification: null,
      isPopupNotificationShow: false,
      isWaitingForResponse: false,
      enablePopupFetch: true,
      fsLoader: false,
      userLoggedIn: null,
    };
  },
  created() {
    this.registerModals();
  },
  mounted() {
    if (this.activeLanguage) {
      this.$i18n.locale = this.activeLanguage;
    }
    document.title = this.metaTitle;
    this.emitter.on("language-switched-loader", () => {
      this.fsLoader = true;
    });
    this.emitter.on("enable-loader", () => {
      this.fsLoader = true;
    });
    this.emitter.on("disable-loader", () => {
      this.fsLoader = false;
    });

    if (location.origin == process.env.VUE_APP_REPAIR_URL) {
      this.setActiveExchange("carservice");
      document.documentElement.setAttribute("active-exchange", "carservice");
    }

    if (location.origin == process.env.VUE_APP_BASE_URL) {
      this.setActiveExchange("carvalue");
      document.documentElement.setAttribute("active-exchange", "carvalue");
    }

    const rememberToken = cookieHandler.methods.getRememberTokenFromCookie();
    if (
      (rememberToken != null && this.loggedInUser == null) ||
      (rememberToken != null &&
        this.loggedInUser &&
        this.getAuthToken !== rememberToken)
    ) {
      // console.log("You are On Repair service subdomain!");
      this.refreshUserLocalStorage(rememberToken);
    }
  },
  beforeUnmount() {
    this.emitter.off("language-switched-loader");
    this.emitter.off("enable-loader");
    this.emitter.off("disable-loader");
  },
  methods: {
    ...mapActions({
      getOwnPopupNotification: "notifications/getOwnPopupNotification",
      getProfile: "profile/getProfile",
      refreshUserData: "users/refreshUserData",
      setActiveExchange: "route/setActiveExchange",
      setIsLoadingGetUser: "users/setIsLoadingGetUser",
      setBackUrl: "route/setBackUrl",
    }),
    ...mapMutations({
      resetUserState: "users/RESET_USER_STATE",
    }),

    getLoggedInUser() {
      this.setIsLoadingGetUser(true);
      this.getProfile().then((res) => {
        this.loggedInUserData = res.user;
        this.loggedInUserData["company"] = res.company;
        this.setIsLoadingGetUser(false);
      });
    },

    refreshUserLocalStorage(rememberToken) {
      this.resetUserState();
      this.getLoggedInUser();
      if (rememberToken != null) {
        this.refreshUserData({
          // user: this.loggedInUserData,
          token: rememberToken,
          lang: this.loggedInUserData && this.loggedInUserData.language_code,
          // company: this.loggedInUserData.company,
        }).then(() => {
          // console.log("User fetched and stored on Repair service subdomain");
        });
      }
    },

    showPopupNotificationModal() {
      if (!this.isPopupNotificationShow) {
        this.isPopupNotificationShow = true;
        this.emitter.emit("openModal", {
          bind: {
            is: PopupNotificationModal,
            message: this.popupNotification.message,
            notificationId: this.popupNotification.id,
          },
          isClosable: false,
          on: {
            markPopupAsRead: this.markPopupAsRead,
            closeModal: () => {
              this.isPopupNotificationShow = false;
            },
          },
        });
      }
    },

    markPopupAsRead() {
      this.popupNotification = null;
    },
  },
  computed: {
    ...mapGetters({
      activeLanguage: "lang/activeLanguage",
      loggedInUser: "users/getLoggedInUserProfile",
      getAuthToken: "users/getAuthToken",
      hasAuthToken: "users/hasAuthToken",
      getBackUrl: "route/backUrl",
    }),
    isTrader() {
      return (
        this.loggedInUser &&
        this.loggedInUser.role_id === VARIABLES.ROLES.TYPE.trader
      );
    },
    isSeller() {
      return (
        this.loggedInUser &&
        this.loggedInUser.role_id === VARIABLES.ROLES.TYPE.trader &&
        this.loggedInUser.user_permissions.includes(
          VARIABLES.PERMISSIONS.TYPE.canSell
        )
      );
    },
    isBuyer() {
      return this.loggedInUser &&
        this.loggedInUser.user_permissions &&
        this.loggedInUser.user_permissions.includes("can_buy")
        ? true
        : false;
    },
    isCountryAdmin() {
      return (
        this.loggedInUser &&
        this.loggedInUser.role_id === VARIABLES.ROLES.TYPE.country_admin
      );
    },
    isSuperAdmin() {
      return (
        this.loggedInUser &&
        this.loggedInUser.role_id === VARIABLES.ROLES.TYPE.super_admin
      );
    },
    isManager() {
      return (
        this.loggedInUser &&
        this.loggedInUser.role_id === VARIABLES.ROLES.TYPE.manager
      );
    },
    metaTitle() {
      if (
        this.$route.meta !== undefined &&
        this.$route.meta.metaTags !== undefined
      ) {
        return this.$t(this.$route.meta.metaTags.title);
      }
      return "Carvalue";
    },
  },
  watch: {
    "$route.path"() {
      document.body.classList.remove("vertical-sidebar-enable");
      document.title = this.metaTitle + " - Carvalue";
      if (
        this.$route.path === "/500" ||
        this.$route.path === "/404" ||
        this.$route.path === "/403"
      ) {
        this.enablePopupFetch = false;
      } else {
        this.enablePopupFetch = true;
      }

      const rememberToken = cookieHandler.methods.getRememberTokenFromCookie();
      if (
        rememberToken &&
        this.enablePopupFetch &&
        this.isTrader &&
        this.isBuyer &&
        !this.isPopupNotificationShow &&
        !this.isWaitingForResponse
      ) {
        this.isWaitingForResponse = true;
        this.getOwnPopupNotification().then((res) => {
          this.isWaitingForResponse = false;
          if (res.popup) {
            this.popupNotification = res.popup;
            this.showPopupNotificationModal();
          }
        });
      }
    },

    "$i18n.locale": function () {
      document.title = this.metaTitle + " - Carvalue";
    },
    $route() {
      if (
        this.$route.path === "/500" ||
        this.$route.path === "/404" ||
        this.$route.path === "/403"
      ) {
        this.enablePopupFetch = false;
      } else {
        this.enablePopupFetch = true;
      }

      if (
        this.enablePopupFetch &&
        this.isTrader &&
        this.isBuyer &&
        !this.isPopupNotificationShow &&
        !this.isWaitingForResponse
      ) {
        const rememberToken =
          cookieHandler.methods.getRememberTokenFromCookie();
        if (rememberToken) {
          this.isWaitingForResponse = true;
          this.getOwnPopupNotification().then((res) => {
            this.isWaitingForResponse = false;
            if (res.popup) {
              this.popupNotification = res.popup;
              this.showPopupNotificationModal();
            }
          });
        }
      }
      if (
        this.getBackUrl &&
        this.$route.name !== "PrivacyPolicy" &&
        this.$route.name !== "CarvalueTermsAndConditions" &&
        this.$route.name !== "RepairTermsAndConditions"
      ) {
        this.setBackUrl(null);
      }
    },
    loggedInUser(newUser, oldUser) {
      // Bejelentkezés után detektálni hogy mikor van meg a user és a permission
      // ahhoz, hogy le lehessen kérni a popup értesítéseket
      if (
        newUser &&
        newUser.user_permissions != null &&
        oldUser &&
        oldUser.user_permissions == undefined
      ) {
        if (
          this.isTrader &&
          this.isBuyer &&
          !this.isPopupNotificationShow &&
          !this.isWaitingForResponse
        ) {
          const rememberToken =
            cookieHandler.methods.getRememberTokenFromCookie();
          if (rememberToken) {
            this.isWaitingForResponse = true;
            this.getOwnPopupNotification().then((res) => {
              this.isWaitingForResponse = false;
              if (res.popup) {
                this.popupNotification = res.popup;
                this.showPopupNotificationModal();
              }
            });
          }
        }
      }
    },
  },
};
</script>

<style>
.no-scroll {
  overflow: hidden;
}
.alert ul {
  margin: 0;
}

.alert li:not(:first-child) {
  margin-top: 5px;
}

.table-row:hover {
  background-color: #f4f1f1;
}

.modalBlur {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #52515187;
  z-index: 9998;
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

.success {
  background-color: #5eb12c !important;
}

.form-group--alert,
.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

.apply-shake {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transition: 0.25s;
  transition-timing-function: ease-out;
}

.form-select {
  cursor: pointer;
}

.select-placeholder {
  color: #878a99 !important;
}

::placeholder {
  color: #878a99 !important;
}

.form-select .multiselect-dropdown {
  z-index: 110;
  overflow: auto;
}

.page-limit-dropdown .multiselect-dropdown {
  overflow: auto;
  max-height: 90px;
}

.form-select .multiselect-caret {
  margin: 0;
  mask-image: none;
  -webkit-mask-image: none;
  background: none;
}

.form-select .multiselect-search {
  display: block;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem 2.7rem 0.5rem 0.9rem;
  -moz-padding-start: calc(0.9rem - 3px);
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.9rem center !important;
  background-size: 16px 12px !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select.multiselect {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.9rem center !important;
  background-size: 16px 12px !important;
}

.form-select.multiselect.is-invalid {
  padding-right: 0.9rem !important;
}

.form-group-multi-select.form-group--error {
  z-index: 110;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}

.popper {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  margin-left: 5px !important;
  max-width: 300px;
}

.tooltip-info-icon {
  font-size: 18px;
  color: #d1d1d1;
  line-height: 1px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-left: 6px;
}

.tooltip-info-icon:hover {
  color: #656565;
}

.tooltip-text {
  font-size: 14px;
}

.placeholder {
  background-color: #d5d4d4 !important;
}

.ph-row-1 {
  animation: placeholder-glow 1s ease-in-out infinite !important;
}

.ph-row-2 {
  animation: placeholder-glow 2s ease-in-out infinite !important;
}

.ph-row-3 {
  animation: placeholder-glow 3s ease-in-out infinite !important;
}

.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  color: #d6d1d1 !important;
}
</style>

<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
