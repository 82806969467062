import ReportService from "@/api/report";

const getReportUsers = (context, payload) => {
  return new Promise((resolve, reject) => {
    ReportService.getReportUsers(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnReportUsers = () => {
  return new Promise((resolve, reject) => {
    ReportService.getOwnReportUsers()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createReportData = (context, payload) => {
  return new Promise((resolve, reject) => {
    ReportService.createReportData(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getReportForExcel = (context, payload) => {
  return new Promise((resolve, reject) => {
    ReportService.getReportForExcel(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getReportUsers,
  getOwnReportUsers,
  createReportData,
  getReportForExcel,
};
