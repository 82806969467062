import axios from "axios";
import ErrorClass from "@/classes/error";
import store from "@/store";
import cookieHandler from "@/mixins/cookieHandler";
import { globalErrorHandler } from "@/globalErrors";

const apiURL = process.env.VUE_APP_API_URL;

function fetchData(endpoint, requestType, requestBody, needAuthToken = true) {
  const hasAuthToken = store.getters["users/hasAuthToken"];
  const rememberToken = cookieHandler.methods.getRememberTokenFromCookie();
  if (!rememberToken && hasAuthToken) {
    // Lejárt session, mert cookie-ban már nincs rememberToken, de a store-ban még igen
    cookieHandler.methods.handleSessionExpired();
    throw new Error("Session expired");
  } else if (rememberToken != null && hasAuthToken) {
    // Van cookie-ban rememberToken, így lehet frissíteni a lejárati dátumot
    cookieHandler.methods.setRememberTokenToCookie(rememberToken);
  }

  const activeLanguage = store.getters["lang/activeLanguage"];

  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    // "Auth-Token": rememberToken,
    "X-Remember-Token": rememberToken,
    locale: activeLanguage,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (needAuthToken != undefined && needAuthToken == false) {
    // nem kell token
    needAuthToken = false;
  }

  const axiosConfig = {
    method: requestType.toLowerCase(),
    url: endpoint,
    headers: headers,
  };

  if (requestBody != undefined && requestBody.generateExcel) {
    axiosConfig.responseType = "arraybuffer";
  }
  if (requestBody != undefined && requestBody.generatePdf) {
    axiosConfig.responseType = "arraybuffer";
  }

  if (requestBody) {
    if (["get"].includes(requestType.toLowerCase())) {
      axiosConfig.params = requestBody;
    } else {
      axiosConfig.data = requestBody;
    }
  }

  return new Promise((resolve, reject) => {
    return axios(axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const errorObject = new ErrorClass(error);
        globalErrorHandler(error);
        reject(errorObject);
      });
  });
}

export { fetchData, apiURL };
