import store from "@/store";

const SET_USER_PROFILE = (state, [data, company]) => {
  if (data && data.language_code) {
    store.dispatch("lang/setLanguage", data.language_code);
  }
  state.loggedInUserProfile = data;
  state.loggedInUserProfile["company"] = company;
};

const SET_LOGIN_USER = (state, [data]) => {
  state.loginUser = data;
};

const SET_AUTH_TOKEN = (state, data) => {
  state.authToken = data;
};

const SET_IS_LOADING_GET_USER = (state, data) => {
  state.isLoadingGetUser = data;
};

const RESET_USER_STATE = (state) => {
  state.authToken = null;
  state.loggedInUserProfile = null;
  state.isLoadingGetUser = true;
};

const SET_REMEMBER_ME = (state, data) => {
  state.rememberMe = data;
};

const SET_BUYER_ACTIVITY_ID = (state, data) => {
  state.buyerActivityId = data;
};

export default {
  SET_USER_PROFILE,
  SET_AUTH_TOKEN,
  SET_IS_LOADING_GET_USER,
  RESET_USER_STATE,
  SET_REMEMBER_ME,
  SET_BUYER_ACTIVITY_ID,
  SET_LOGIN_USER,
};
