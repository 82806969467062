import { fetchData, apiURL } from "./api_helper";

// Countries API services

// GET COUNTRIES

const getCountries = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/countries";

    fetchData(endpoint, "get", null, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE COUNTRIES

const updateCountries = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/countries";

    fetchData(endpoint, "put", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET FEEDBACK TIME

const getFeedbackTime = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/countries/visible";

    fetchData(endpoint, "get", null, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE FEEDBACK TIME

const updateFeedbackTime = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/countries/visible";

    fetchData(endpoint, "put", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getCountries,
  updateCountries,
  getFeedbackTime,
  updateFeedbackTime,
};
