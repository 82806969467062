import { fetchData, apiURL } from "./api_helper";

// Report API services

// GET REPORT USERS

const getReportUsers = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/search`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET OWN REPORT USERS

const getOwnReportUsers = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/own/search/riport?with_names=1";

    fetchData(endpoint, "get", null, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// CREATE REPORT DATA

const createReportData = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/offers/riport";

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET REPORT TO EXCEL

const getReportForExcel = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/offers/riport/excel";

    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getReportUsers,
  getOwnReportUsers,
  createReportData,
  getReportForExcel,
};
