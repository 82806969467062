import SaleService from "@/api/sales";

const getVehiclesToSales = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.getVehiclesToSales(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createSale = (context, payload) => {
  return new Promise((resolve, reject) => {
    const { requestBody, id } = payload;
    SaleService.createSale(requestBody, id)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleDetails = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.getVehicleDetails(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateVehicleDetails = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.updateVehicleDetails(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getEntries = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.getEntries(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createEntry = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.createEntry(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendEmail = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.sendEmail(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const unarchive = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.unarchive(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequestsForSales = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.getLastSearchOfferRequestsForSales(payload)
      .then((res) => {
        //context.commit("SET_LAST_OFFER_REQUESTS", res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const generateExcel = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.generateExcel(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const closeBilled = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.closeBilled(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTraderList = (context, payload) => {
  return new Promise((resolve, reject) => {
    SaleService.getTraderList(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getVehiclesToSales,
  createSale,
  getVehicleDetails,
  updateVehicleDetails,
  getEntries,
  createEntry,
  sendEmail,
  unarchive,
  getLastSearchOfferRequestsForSales,
  generateExcel,
  closeBilled,
  getTraderList,
};
