const getLoggedInGuestVehicle = (state) => state.loggedInGuestVehicle;
const getValidGuest = (state) => state.validGuest;
const getBidId = (state) => state.bidId;
const getPin = (state) => state.pin;
const getHasDatas = (state) => state.hasDatas;
const getFirstLogin = (state) => state.firstLogin;

export default {
  getLoggedInGuestVehicle,
  getValidGuest,
  getBidId,
  getPin,
  getHasDatas,
  getFirstLogin,
};
