import FilesService from "@/api/files";

const deleteFile = (context, payload) => {
  return new Promise((resolve, reject) => {
    FilesService.deleteFile(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteAllImages = (context, payload) => {
  return new Promise((resolve, reject) => {
    FilesService.deleteAllImages(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const downloadFile = (context, payload) => {
  return new Promise((resolve, reject) => {
    FilesService.downloadFile(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateImage = (context, payload) => {
  return new Promise((resolve, reject) => {
    FilesService.updateImage(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateImageDesc = (context, payload) => {
  return new Promise((resolve, reject) => {
    FilesService.updateImageDesc(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  deleteFile,
  deleteAllImages,
  downloadFile,
  updateImage,
  updateImageDesc,
};
