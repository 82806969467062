import ProfileService from "@/api/profile";
import store from "@/store";

const getProfile = () => {
  return new Promise((resolve, reject) => {
    ProfileService.getProfile()
      .then((res) => {
        store.commit("users/SET_USER_PROFILE", [res.user, res.company]);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateProfile = (context, payload) => {
  return new Promise((resolve, reject) => {
    ProfileService.updateProfile(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const changePassword = (context, payload) => {
  return new Promise((resolve, reject) => {
    ProfileService.changePassword(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getNotificationInfo = () => {
  return new Promise((resolve, reject) => {
    ProfileService.getNotificationInfo()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateNotification = (context, payload) => {
  return new Promise((resolve, reject) => {
    ProfileService.updateNotification(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const changeLanguage = (context, payload) => {
  return new Promise((resolve, reject) => {
    ProfileService.changeLanguage(payload)
      .then((res) => {
        resolve(res);
        location.reload();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateLastActivity = (context, payload) => {
  return new Promise((resolve, reject) => {
    ProfileService.updateLastActivity(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastExchange = () => {
  return new Promise((resolve, reject) => {
    ProfileService.getLastExchange()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getProfile,
  updateProfile,
  changePassword,
  getNotificationInfo,
  updateNotification,
  changeLanguage,
  updateLastActivity,
  getLastExchange,
};
