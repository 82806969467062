const SET_GUEST_VEHICLE = (state, data) => {
  state.loggedInGuestVehicle = data;
};

const SET_VALID_GUEST = (state, data) => {
  state.validGuest = data;
};

const SET_BID_ID = (state, payload) => {
  state.bidId = payload;
};

const SET_PIN = (state, payload) => {
  state.pin = payload;
};

const SET_HAS_DATAS = (state, payload) => {
  state.hasDatas = payload;
};

const SET_FIRST_LOGIN = (state, payload) => {
  state.firstLogin = payload;
};

export default {
  SET_GUEST_VEHICLE,
  SET_VALID_GUEST,
  SET_BID_ID,
  SET_PIN,
  SET_HAS_DATAS,
  SET_FIRST_LOGIN,
};
