import UserService from "@/api/outside";

// LOGIN + (ADD AUTH)

const loginGuest = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.loginGuest(payload)
      .then((res) => {
        context.commit("SET_GUEST_VEHICLE", res);
        context.commit("SET_VALID_GUEST", true);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET DATAS

const getOwnerDatas = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.getOwnerDatas(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// SEND DATAS

const sendInformation = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.sendInformation(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendContact = (context, payload) => {
  return new Promise((resolve, reject) => {
    UserService.sendContact(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// SAVE IDENTIFICATIONS

const setBidId = (context, payload) => {
  context.commit("SET_BID_ID", payload);
};

const setPin = (context, payload) => {
  context.commit("SET_PIN", payload);
};

// SET CAN'T SEE THE FORM - LOST AUTH - SET STATE TO DEFAULT

const exitGuest = (context) => {
  context.commit("SET_VALID_GUEST", false);
  context.commit("SET_BID_ID", null);
  context.commit("SET_PIN", null);
  context.commit("SET_GUEST_VEHICLE", {});
  context.commit("SET_HAS_DATAS", null);
  context.commit("SET_FIRST_LOGIN", null);
};

// SET ARE THERE DATAS

const setHasDatas = (context, payload) => {
  context.commit("SET_HAS_DATAS", payload);
};

// SET THE FIRST LOGIN FOR A GLOBAL MESSAGE

const setFirstLogin = (context, payload) => {
  context.commit("SET_FIRST_LOGIN", payload);
};

export default {
  loginGuest,
  getOwnerDatas,
  sendInformation,
  setBidId,
  setPin,
  exitGuest,
  setHasDatas,
  setFirstLogin,
  sendContact,
};
