import VehicleService from "@/api/vehicles";

const createVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.createVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.updateVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const lastUpdateVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.lastUpdateVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDraftVehicleData = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getDraftVehicleData(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleTypes = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleTypes(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleBrands = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleBrands(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleModels = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleModels(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleSubModels = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleSubModels(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleDamages = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleDamages(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleEquipments = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleEquipments(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnOffers = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOwnOffers(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getExchangeCountries = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getExchangeCountries(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getExchange = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getExchange(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOffer = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOffer(payload)
      .then((res) => {
        context.commit("SET_OFFER_DATA", res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastUploadedVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getLastUploadedVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const blockOffer = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.blockOffer(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const duplicateOffer = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.duplicateOffer(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteOffer = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.deleteOffer(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const validateOffer = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.validateOffer(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferDamages = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOfferDamages(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferWithAllData = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOfferWithAllData(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnOpenOffers = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOwnOpenOffers(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnClosedOffers = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOwnClosedOffers(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferBids = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOfferBids(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const downloadDetailPdf = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.downloadDetailPdf(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getSummaryStatistics = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getSummaryStatistics(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequests = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getLastSearchOfferRequests(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getExchangeByStatus = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getExchangeByStatus(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequestsForAll = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getLastSearchOfferRequestsForAll(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequestsForOwnVehicle = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getLastSearchOfferRequestsForOwnVehicle(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOwnOfferRequests = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getLastSearchOwnOfferRequests(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferBidsForUser = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getOfferBidsForUser(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleImages = (context, payload) => {
  return new Promise((resolve, reject) => {
    VehicleService.getVehicleImages(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  createVehicle,
  updateVehicle,
  lastUpdateVehicle,
  getDraftVehicleData,
  getVehicleTypes,
  getVehicleBrands,
  getVehicleModels,
  getVehicleSubModels,
  getVehicleDamages,
  getVehicleEquipments,
  getOwnOffers,
  getExchangeCountries,
  getExchange,
  getOffer,
  getLastUploadedVehicle,
  blockOffer,
  duplicateOffer,
  deleteOffer,
  getOfferDamages,
  validateOffer,
  getOfferWithAllData,
  getOwnOpenOffers,
  getOwnClosedOffers,
  getOfferBids,
  getOfferBidsForUser,
  downloadDetailPdf,
  getSummaryStatistics,
  getLastSearchOfferRequests,
  getExchangeByStatus,
  getLastSearchOfferRequestsForAll,
  getLastSearchOfferRequestsForOwnVehicle,
  getLastSearchOwnOfferRequests,
  getVehicleImages,
};
