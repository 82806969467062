export default class Error {
  constructor(error) {
    this.error = error;
  }

  dataError() {
    this.error.response.data.data;
  }

  messageError() {
    if (
      this.error.response.data.type &&
      !Array.isArray(this.error.response.data.message)
    ) {
      return this.error.response.data.type;
    } else if (Array.isArray(this.error.response.data.message)) {
      return this.error.response.data.message;
    } else {
      return this.error.response.data.message;
    }
  }

  get getMessage() {
    if (!this.error.response.data.data) {
      return this.messageError();
    }
    return this.dataError();
  }
}
