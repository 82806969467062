const SET_USER_FORM_DATA = (state, data) => {
  state.originalUserFormData = data;
};

const SET_COMPANY_FORM_DATA = (state, data) => {
  state.originalCompanyFormData = data;
};

const SET_FILES_FORM_DATA = (state, data) => {
  state.originalFilesFormData = data;
};

const SET_SYSTEM_FORM_DATA = (state, data) => {
  state.originalSystemFormData = data;
};

const SET_COUNTRIES_FORM_DATA = (state, data) => {
  state.originalCountriesFormData = data;
};

// const SET_VEHICLE_DRAFT_FORM_DATA = (state, data) => {
//   state.originalVehicleDraftFormData = data;
// };

export default {
  SET_USER_FORM_DATA,
  SET_COMPANY_FORM_DATA,
  SET_FILES_FORM_DATA,
  SET_SYSTEM_FORM_DATA,
  SET_COUNTRIES_FORM_DATA,
  // SET_VEHICLE_DRAFT_FORM_DATA,
};
