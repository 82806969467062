import store from "@/store";
import i18n from "./i18n";
import router from "./router";
import cookieHandler from "@/mixins/cookieHandler";
import { useToast } from "vue-toastification";
const toast = useToast();

export const globalErrorTypes = ["UNAUTHORIZED"];

const globalErrorTexts = {
  UNAUTHORIZED: "app.validations.serverError401",
  NOTFOUND: "app.notifications.pageNotAllowed",
  INTERNALSERVERERROR: "app.validations.serverError500",
};

export const globalErrorHandler = (error) => {
  const rememberToken = cookieHandler.methods.getRememberTokenFromCookie();
  const hasAuthToken = store.getters["users/hasAuthToken"];
  if (error.response && error.response.data) {
    if (
      error.response.data.code === 401 &&
      error.response.data.message === "Unauthorized" &&
      rememberToken != null &&
      hasAuthToken
    ) {
      toast.error(i18n.global.t(globalErrorTexts.UNAUTHORIZED));
      // store.dispatch("users/logOut");
      // router.push("/login");
      router.push("/403");
    } else if (
      error.response.data.code === 404 &&
      error.response.data.message === "Not found" &&
      rememberToken != null &&
      hasAuthToken
    ) {
      // toast.error(i18n.global.t(globalErrorTexts.NOTFOUND));
      router.push("/404");
    } else if (
      error.response.data.code === 500 &&
      rememberToken != null &&
      hasAuthToken
    ) {
      toast.error(i18n.global.t(globalErrorTexts.INTERNALSERVERERROR));
      router.push("/500");
    }
  }
  console.log(error);
  return Promise.resolve(error);
  // return Promise.reject(error);
};
