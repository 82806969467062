const getLoggedInUserProfile = (state) => state.loggedInUserProfile;
const getAuthToken = (state) => state.authToken;
const hasAuthToken = (state) => state.authToken != undefined;
const getRememberMe = (state) => state.rememberMe;
const isLoadingGetUser = (state) => state.isLoadingGetUser;

const getBuyerActivityId = (state) => state.buyerActivityId;
const loginUser = (state) => state.loginUser;

export default {
  getLoggedInUserProfile,
  getAuthToken,
  hasAuthToken,
  getRememberMe,
  getBuyerActivityId,
  isLoadingGetUser,
  loginUser,
};
