import { fetchData, apiURL } from "./api_helper";

// Files API services

// DELETE FILE

const deleteFile = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/files/${requestBody.fileId}`;

    fetchData(endpoint, "delete", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// DELETE ALL IMAGES

const deleteAllImages = (id) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}/images`;

    fetchData(endpoint, "delete", null, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// DOWNLOAD FILE

const downloadFile = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/files/${requestBody.fileId}`;

    requestBody.generateExcel = true;
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE IMAGE

const updateImage = ([requestBody, id]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/files/${id}`;

    fetchData(endpoint, "put", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE IMAGE DESCRIPTION

const updateImageDesc = ([requestBody, id]) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/files/${id}/title`;

    fetchData(endpoint, "put", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  deleteFile,
  deleteAllImages,
  downloadFile,
  updateImage,
  updateImageDesc,
};
