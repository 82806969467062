import i18n from "@/i18n";

const setLanguage = (context, language) => {
  i18n.global.locale = language;
  context.commit("SET_LANGUAGE", language);
};

export default {
  setLanguage,
};
