import { fetchData, apiURL } from "./api_helper";

// Notifications API services

const getPopupNotifications = (requestBody, page, limit) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/notifications/popups`;
    if (endpoint.includes("?")) {
      endpoint.concat(`&page=${page}&limit=${limit}`);
    } else {
      endpoint.concat(`?page=${page}&limit=${limit}`);
    }

    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createPopupNotification = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/notifications/popups";
    fetchData(endpoint, "post", requestBody)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deletePopupNotification = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL + `/api/notifications/popups/${requestBody.notificationId}`;

    fetchData(endpoint, "delete", requestBody)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnPopupNotification = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/notifications/popups/own`;

    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const markPopupAsRead = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL + `/api/notifications/popups/${requestBody.notificationId}/seen`;

    fetchData(endpoint, "post", requestBody)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET INTERNAL NOTIFICATIONS

const getInternalNotifications = (page) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/notifications/internals?page=${page}`;
    fetchData(endpoint, "get", null, true, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// MARK INTERNAL NOTIFICATIONS AS READ

const markInternalNotificationsAsRead = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/notifications/internals/seen`;

    fetchData(endpoint, "post", null)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getPopupNotifications,
  createPopupNotification,
  deletePopupNotification,
  getOwnPopupNotification,
  markPopupAsRead,
  getInternalNotifications,
  markInternalNotificationsAsRead,
};
