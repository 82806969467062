<template>
  <div
    id="global-button"
    :class="'align-items-' + flexPosition + ' ' + flexClass"
    @click="buttonClicked"
  >
    <button
      class="btn global-button d-flex justify-content-center align-items-center"
      :type="type"
      :id="id"
      :class="classes"
      :role="role"
      :data-bs-toggle="dataBsToggle"
      :data-bs-target="dataBsTarget"
      :aria-controls="ariaControls"
      :disabled="disabled ? true : false"
      :tabindex="tabindex"
      :data-cy="`button-${id}`"
    >
      <span v-show="loading" class="loader"></span>
      <i v-show="icon" :class="icon"></i>
      <span v-if="title">{{ title }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "GlobalButton",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    classes: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    flexPosition: {
      type: String,
      required: false,
      default: "start",
    },
    flexClass: {
      type: String,
      required: false,
      default: "",
    },
    loading: Boolean,
    icon: {
      type: String,
      required: false,
    },
    role: {
      type: String,
      required: false,
    },
    dataBsToggle: {
      type: String,
      required: false,
    },
    dataBsTarget: {
      type: String,
      required: false,
    },
    ariaControls: {
      type: String,
      required: false,
    },
    tabindex: {
      type: String,
      required: false,
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    buttonClicked() {
      if (!this.disabled && !this.loading) {
        this.$emit("buttonClicked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  margin-right: 10px;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chart-button {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  width: 260px;
  height: 135px;
  text-align: center;
  flex-wrap: wrap !important;
  box-shadow: none;
}

.chart-button span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #9fa2b4;
  display: block;
  flex-wrap: wrap;
  flex-basis: 300px;
}

.chart-button h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 1px;
  color: #252733;
  display: block;
  flex-wrap: wrap;
  flex-basis: 300px;
}

.chart-button:hover {
  color: #3751ff;
  border: 1px solid #3751ff;
}

.chart-button:hover span,
.chart-button:hover h3 {
  color: #3751ff;
}

.chart-button.active {
  color: #3751ff;
  border: 1px solid #3751ff;
}

.chart-button.active span,
.chart-button.active h3 {
  color: #3751ff;
}
</style>
