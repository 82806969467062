import { fetchData, apiURL } from "./api_helper";

// Profile API services

// GET PROFILE

const getProfile = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me";

    fetchData(endpoint, "get", null, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE PROFILE

const updateProfile = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me";

    fetchData(endpoint, "put", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// CHANGE PASSWORD

const changePassword = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me/password/change";

    fetchData(endpoint, "put", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getNotificationInfo = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me/notifications";

    fetchData(endpoint, "get")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateNotification = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me/notifications";

    fetchData(endpoint, "put", requestBody)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const changeLanguage = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me/language";

    fetchData(endpoint, "put", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateLastActivity = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/me/activity";

    fetchData(endpoint, "post", requestBody, true, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastExchange = () => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/offers/own/lastExchange";

    fetchData(endpoint, "get", null, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getProfile,
  updateProfile,
  changePassword,
  getNotificationInfo,
  updateNotification,
  changeLanguage,
  updateLastActivity,
  getLastExchange,
};
