import CountryService from "@/api/countries";

const getCountries = (context) => {
  return new Promise((resolve, reject) => {
    const allCountries = [];
    const allCountriesByID = {};
    const allCountriesByCode = {};
    CountryService.getCountries()
      .then((res) => {
        res.forEach((item) => {
          allCountries.push({
            id: item.id,
            value: item.id,
            code: item.code,
            name: item.name,
            enabled: item.enabled,
            default_vat: item.default_vat,
            created_at: item.created_at,
            updated_at: item.updated_at,
            closed_interval: item.closed_interval,
            currency: item.currency,
            date_format: item.date_format,
            date_time_format: item.date_time_format,
          });

          allCountriesByID[item.id] = item.code;
          allCountriesByCode[item.code] = item.id;
        });

        context.commit("SET_COUNTRIES", res);
        context.commit("SET_COUNTRIES_BY_ID", allCountriesByID);
        context.commit("SET_COUNTRIES_BY_CODE", allCountriesByCode);

        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateCountries = (context, payload) => {
  return new Promise((resolve, reject) => {
    CountryService.updateCountries(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getFeedbackTime = () => {
  return new Promise((resolve, reject) => {
    CountryService.getFeedbackTime()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateFeedbackTime = (context, payload) => {
  return new Promise((resolve, reject) => {
    CountryService.updateFeedbackTime(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getCountries,
  updateCountries,
  getFeedbackTime,
  updateFeedbackTime,
};
