import { fetchData, apiURL } from "./api_helper";

// Outside - Guest API services

// LOGIN

const loginGuest = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/offers/access";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET OWNER DATAS

const getOwnerDatas = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/public`;

    fetchData(endpoint, "get", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// SEND INFORMATIONS

const sendInformation = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/owners";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendContact = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/mailgun";

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  loginGuest,
  getOwnerDatas,
  sendInformation,
  sendContact,
};
