import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modalComponent: undefined,
      modalActive: false,
    };
  },
  computed: {
    modalKey() {
      if (!this.modalComponent.bind.is.name) {
        return Math.floor(Math.random() * Math.floor(100000));
      }
      return `${this.modalComponent.bind.is.name}_${Math.floor(
        Math.random() * Math.floor(1000)
      )}`;
    },
  },
  mounted() {
    document.addEventListener("keyup", this.handleKeyup);
  },
  methods: {
    registerModals() {
      this.emitter.on("openModal", (payload) => {
        this.modalComponent = payload;
        this.modalActive = true;
        setTimeout(() => {
          document.getElementById("backdrop").classList.add("modalBlur");
          document.body.classList.add("no-scroll");
        }, 100);
      });

      this.emitter.on("closeModal", () => {
        this.modalActive = false;
        setTimeout(() => {
          document.getElementById("backdrop").classList.remove("modalBlur");
          document.body.classList.remove("no-scroll");
        }, 100);
      });
    },
    handleKeyup(e) {
      if (e.keyCode === 27 && this.modalActive) {
        this.modalActive = false;
        setTimeout(() => {
          document.body.classList.remove("no-scroll");
          document.getElementById("backdrop").classList.remove("modalBlur");
        }, 100);
      }
    },
  },
  beforeUnmount() {
    this.emitter.off("openModal");
    this.emitter.off("closeModal");
    document.removeEventListener("keyup", this.handleKeyup);
  },
};
