import { fetchData, apiURL } from "./api_helper";

// Vehicles API services

// CREATE VEHICLE

const createVehicle = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/offers";

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// UPDATE VEHICLE

const updateVehicle = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}`;

    fetchData(endpoint, "put", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// LAST UPDATE VEHICLE

const lastUpdateVehicle = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}`;

    fetchData(endpoint, "put", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET DRAFT VEHICLES

const getDraftVehicleData = ({ id, step }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}/all?step=${step}`;

    fetchData(endpoint, "get", null, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET VEHICLE TYPES

const getVehicleTypes = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/vehicles/types";

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET VEHICLE BRANDS

const getVehicleBrands = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/vehicles/types/${requestBody}/brands`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET VEHICLE MODELS

const getVehicleModels = ({ requestBody, typeID }) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL + `/api/vehicles/types/${typeID}/brands/${requestBody}/models`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET VEHICLE SUBMODELS

const getVehicleSubModels = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/vehicles/models/${requestBody}/submodels`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET VEHICLE DAMAGES

const getVehicleDamages = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/vehicles/damages`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET VEHICLE EQUIPMENTS

const getVehicleEquipments = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/vehicles/equipments`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET OWN OFFERS

const getOwnOffers = (requestBody, page, limit, type, searchText) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/own`;
    if (endpoint.includes("?")) {
      endpoint.concat(`&page=${page}&limit=${limit}`);
    } else {
      endpoint.concat(`?page=${page}&limit=${limit}`);
    }
    if (endpoint.includes("?")) {
      endpoint.concat(`&type=${type}`);
    } else {
      endpoint.concat(`?type=${type}`);
    }
    if (endpoint.includes("?")) {
      endpoint.concat(`&searchText=${searchText}`);
    } else {
      endpoint.concat(`?searchText=${searchText}`);
    }
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET EXCHANGE COUNTRIES

const getExchangeCountries = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/own/exchanges`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET EXCHANGE

const getExchange = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/exchanges/${requestBody.countryId}`;
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOffer = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.offerId}`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET LAST UPLOADED VEHICLE DATA

const getLastUploadedVehicle = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/offers/own/last";
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const blockOffer = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}/block`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const duplicateOffer = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}/duplicate`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteOffer = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}`;

    fetchData(endpoint, "delete", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const validateOffer = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}/validate`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferDamages = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.offerId}/damages`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferWithAllData = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.offerId}/all`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnOpenOffers = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/own/open`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnClosedOffers = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/own/closed`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferBids = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.offerId}/bids`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOfferBidsForUser = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL + `/api/offers/${requestBody.offerId}/bids/${requestBody.userId}`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const downloadDetailPdf = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.offerId}/pdf`;
    requestBody.generatePdf = true;
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// SET FAVOURITE SEARCH VEHICLE

const createFavouriteSearchVehicle = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/own/search";

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// GET FAVOURITE SEARCH VEHICLE

const getFavouriteSearchVehicle = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/own/search/favourite";

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// DELETE FAVOURITE SEARCH VEHICLE

const deleteFavouriteSearchVehicle = ({ requestBody, id }) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/own/search/${id}`;

    fetchData(endpoint, "delete", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getSummaryStatistics = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/summary`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequests = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + "/api/users/own/search/offerRequests";

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getExchangeByStatus = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/all`;
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequestsForAll = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL + `/api/users/own/search/admin/offerRequests/${requestBody.type}`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequestsForOwnVehicle = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint =
      apiURL + `/api/users/own/search/offerRequests/own/${requestBody.type}`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOwnOfferRequests = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/own/search/offer/${requestBody.type}`;

    fetchData(endpoint, "get", null, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleImages = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.offerRequestId}/images`;

    fetchData(endpoint, "get", null, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  createVehicle,
  updateVehicle,
  lastUpdateVehicle,
  getDraftVehicleData,
  getVehicleTypes,
  getVehicleBrands,
  getVehicleModels,
  getVehicleSubModels,
  getVehicleDamages,
  getVehicleEquipments,
  getOwnOffers,
  getExchangeCountries,
  getExchange,
  getOffer,
  getLastUploadedVehicle,
  blockOffer,
  duplicateOffer,
  deleteOffer,
  getOfferDamages,
  validateOffer,
  getOfferWithAllData,
  getOwnOpenOffers,
  getOwnClosedOffers,
  getOfferBids,
  getOfferBidsForUser,
  downloadDetailPdf,
  createFavouriteSearchVehicle,
  getFavouriteSearchVehicle,
  deleteFavouriteSearchVehicle,
  getSummaryStatistics,
  getLastSearchOfferRequests,
  getExchangeByStatus,
  getLastSearchOfferRequestsForAll,
  getLastSearchOfferRequestsForOwnVehicle,
  getLastSearchOwnOfferRequests,
  getVehicleImages,
};
