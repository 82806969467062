import { fetchData, apiURL } from "./api_helper";

const getVehiclesToSales = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/sales`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createSale = (requestBody, id) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${id}/sale`;

    fetchData(endpoint, "post", requestBody, false)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVehicleDetails = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.id}/sale`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateVehicleDetails = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.id}/sale`;

    fetchData(endpoint, "put", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getEntries = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.id}/sale/logs`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createEntry = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.id}/sale/comment`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendEmail = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.id}/sale/email`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const unarchive = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/${requestBody.id}/unarchive`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLastSearchOfferRequestsForSales = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/users/own/search/sale/${requestBody.type}`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const generateExcel = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/sales/excel`;
    requestBody.generateExcel = true;
    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const closeBilled = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/sales/closeBilled`;

    fetchData(endpoint, "post", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTraderList = (requestBody) => {
  return new Promise((resolve, reject) => {
    let endpoint = apiURL + `/api/offers/sales/buyers`;

    fetchData(endpoint, "get", requestBody, true)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getVehiclesToSales,
  createSale,
  getVehicleDetails,
  updateVehicleDetails,
  getEntries,
  createEntry,
  sendEmail,
  unarchive,
  getLastSearchOfferRequestsForSales,
  generateExcel,
  closeBilled,
  getTraderList,
};
