const state = {
  originalUserFormData: {},
  originalCompanyFormData: {},
  originalFilesFormData: {},
  originalSystemFormData: {},
  originalCountriesFormData: {},
  // originalVehicleDraftFormData: {},
};

export default state;
